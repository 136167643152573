import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { nftParameters } from "../const";

class MintService {
  web3;
  
  constructor() {
    this.web3 = createAlchemyWeb3(nftParameters.alchemyId);
  }

  async mint(requestedAmount, walletAddress) {
    console.log("walletAddress",walletAddress);
    window.contract = new this.web3.eth.Contract(nftParameters.contractAbi, nftParameters.contractAddress, {
      from: walletAddress,
    });
    const priceInEth = (
      parseInt(this.web3.utils.toWei(nftParameters.priceInEth, "ether")) * requestedAmount
    ).toString(16);
    const transactionParameters = {
      to: nftParameters.contractAddress,
      from: window.ethereum.selectedAddress,
      data: window.contract.methods.mint(requestedAmount).encodeABI(),
      value: priceInEth,
    };
    return await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
  }

}
export const mintService = new MintService();
