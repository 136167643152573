import { useEffect } from "react";
import { walletService } from "../service/Wallet.service";
import { walletConnectService } from "../service/WalletConnect.service";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount  } from "wagmi";


function ConnectWalletButton(props) {
  useEffect(() => {
    walletService.init();
    return () => {
      walletService.destroy();
    };
  }, []);

  async function connect() {
    walletService.connect();
  }

  return (
    <>
        <button
          type="button"
          id="connect-wallet"
          className={props.status === "connected" ? "hidden" : ""}
          onClick={connect}
        >
          CONNECT WALLET
        </button>
    </>
  );
}

function WalletConnectBtn(props) {

  useEffect(() => {
    walletConnectService.init();
    return () => {
      walletConnectService.destroy();
    };
  }, []);

  // async function connect() {
  //   walletConnectService.connect();
  // }

  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()
  return (
    <>

     {!isConnected && (
        <>
          <button
          type="button"
          id="walletConnect"
          className={props.status === "connected" ? "hidden" : ""}
          onClick={open}
        >
          CONNECT WALLET
        </button>
        </>
      )}
      
    </>
  );
}




export { ConnectWalletButton, WalletConnectBtn }
