import './App.css';
import NftBuyBox from './components/NftBuyBox';
import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { chain, configureChains, createClient, WagmiConfig, useAccount } from 'wagmi'
import { MintNFT } from './components/NftConnectWallet'

const projectId = "80c59909d754b1bf4572be121005842c"

// 2. Configure wagmi client
const chains = [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum]
const { provider } = configureChains(chains, [walletConnectProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: 'web3Modal', chains }),
  provider
})

// 3. Configure modal ethereum client
const ethereumClient = new EthereumClient(wagmiClient, chains)

function App() {
const { isConnected } = useAccount()

  if(isConnected){
    return (
      <>
        <WagmiConfig client={wagmiClient}>
          <div className="App">
            <MintNFT />
          </div>
        </WagmiConfig>
  
        <Web3Modal
          projectId={projectId}
          theme="dark"
          accentColor="default"
          ethereumClient={ethereumClient}
        />
      </>
    )
  }
  else {
    
  
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <div className="App">
          <NftBuyBox></NftBuyBox>
        </div>
      </WagmiConfig>

      <Web3Modal
        projectId={projectId}
        theme="dark"
        accentColor="default"
        ethereumClient={ethereumClient}
      />
    </>
  )
}
}

export default App;