import { trigger } from "./event.service";

class WalletService {
  addrArray;
  addressArray(data) {
    if(!data) {
      return this.addrArray;
    }
    this.addrArray = data;
  }

  async init() {
    if (window.ethereum) {
      if (this.addressArray()) {
        this.triggerConnectSucceeded(this.addressArray());
      }
      window.ethereum.on("accountsChanged", this.triggerConnectSucceeded);
      window.ethereum.on("chainChanged", this.triggerChainChanged);
    }
  }

  destroy() {
    if (window.ethereum) {
      window.ethereum.removeListener("accountsChanged", this.triggerConnectSucceeded);
      window.ethereum.removeListener("chainChanged", this.triggerChainChanged);
    }
  }

  async connect() {
    if (window.ethereum) {
      if (this.addressArray()) {
        this.triggerConnectSucceeded(this.addressArray());
        return;
      }

      try {
        this.addressArray(await window.ethereum.request({
          method: "eth_requestAccounts",
        }));
        this.triggerConnectSucceeded(this.addressArray());
      } catch (err) {
        this.triggerConnectFailed(err.message);
      }
    } else {
      this.triggerConnectFailed("Metamask is not installed");
    }
  }

  triggerConnectSucceeded(addressArray) {
    if (addressArray.length > 0) {
      trigger("connect", { status: "success", walletAddress: addressArray[0] });
    }
  }

  triggerConnectFailed(message) {
    trigger("connect", { status: "error", message: message });
  }

  triggerChainChanged() {
    window.location.reload();
  }
}
export const walletService = new WalletService();
