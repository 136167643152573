import { nftParameters } from "../const";
import { useEffect, useState } from "react";
import { mintService } from "../service/Mint.service";
import { on } from "../service/event.service";
import { off } from "process";
import {ConnectWalletButton, WalletConnectBtn} from "./ConnectWalletButton";

import { useAccount } from 'wagmi'

function NftBuyBox() {
  useEffect(() => {
    on("connect", handleConnectWalletEvent);
    
    return () => {
      off("connect", handleConnectWalletEvent);
    };
  }, []);

  const [walletAddress, setWalletAddress] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [nftCount, setNftCount] = useState("1");
  const [hash, setHash] = useState(null);

  const { address, isConnected } = useAccount()

  console.log("isConnected", isConnected);

  const handleConnectWalletEvent = ({ detail }) => {
    const { status } = detail;
    console.log("detail", detail);
    if (status === "success") {
      setWalletAddress(detail.walletAddress);
      setErrorMsg(undefined);
    } else {
      setWalletAddress(undefined);
      setErrorMsg(detail.message);
    }
  };

 

  async function onMint() {
    let requestedAmount = parseInt(nftCount);
    if (isNaN(requestedAmount) || requestedAmount < 1 || requestedAmount > 5) {
      requestedAmount = 1;
    }

    try {
      const txHash = await mintService.mint(requestedAmount, address);
      setHash(txHash);
    
    } catch (err) {
      setErrorMsg(err.message);
    }
  }

  return (
    <>
      <ConnectWalletButton status={!(walletAddress || isConnected) ? "disconnected" : "connected"}></ConnectWalletButton>
      <WalletConnectBtn status={!(walletAddress || isConnected) ? "disconnected" : "connected"}></WalletConnectBtn>
      {(walletAddress || isConnected) &&
        <div id="mint">
          <select
            id="nft-count"
            className="nft-count"
            value={nftCount}
            onChange={(e) => {
              setNftCount(e.target.value);
            }}
          >
            <option value="1">1 NFT</option>
            <option value="2">2 NFTs</option>
            <option value="3">3 NFTs</option>
          </select>
          <i className="arrow down"></i>

          <button type="button" className="hero-btn button rounded outline purple" onClick={onMint}>
            MINT
          </button>
        </div>
      }

      <p id="nft-price">{nftParameters.priceInEth} ETH per NFT</p>
      <p id="mint-message">
        {!!walletAddress && <span className="wallet-address">Connected with wallet: {walletAddress}</span>}
        <span className="errorMsg">{errorMsg}</span>
        {!!hash && (
          <span className="hash">
            Transaction Hash: <a href={`https://polygonscan.com/tx/${hash}`}>{hash}</a>
          </span>
        )}
      </p>
    </>
  );
}
export default NftBuyBox;
