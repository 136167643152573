import * as React from 'react'
import { useState } from "react";
import { nftParameters } from "../const";
import { mintService } from "../service/Mint.service";
import {ConnectWalletButton, WalletConnectBtn} from "./ConnectWalletButton";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount
} from 'wagmi'

export function MintNFT() {

  const { address,isConnected } = useAccount()
  const [walletAddress] = useState();
  const [nftCount, setNftCount] = useState("1");
  console.log("nftCount",nftCount);
  const mintValue = nftParameters.priceInEth * nftCount;
  const totalValue = mintService.web3.utils.toWei(mintValue.toString(), 'ether') ;

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: nftParameters.contractAddress,
    functionName: 'mint',
    stateMutability: "payable",
    abi: [
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_mintAmount",
            "type": "uint256"
          }
        ],
        name: 'mint',
        type: 'function',
        stateMutability: 'payable',
        outputs: [],
      },
    ],
    args: [nftCount],
    overrides: { value: totalValue, gasLimit: 150000 }
  })
  const { data, error, isError, write } = useContractWrite(config)
 
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })
 
  return (

    <>
    <ConnectWalletButton status={!(walletAddress || isConnected) ? "disconnected" : "connected"}></ConnectWalletButton>
    <WalletConnectBtn status={!(walletAddress || isConnected) ? "disconnected" : "connected"}></WalletConnectBtn>
    {(walletAddress || isConnected) &&
      <div id="mint">
        <select
          id="nft-count"
          className="nft-count"
          value={nftCount}
          onChange={(e) => {
            setNftCount(e.target.value);
          }}
        >
          <option value="1">1 NFT</option>
          <option value="2">2 NFTs</option>
          <option value="3">3 NFTs</option>
        </select>
        <i className="arrow down"></i>

        <button disabled={!write || isLoading} onClick={() => write()}>
          {isLoading ? 'Minting...' : 'Mint'}
        </button>
      </div>
    }

    <p id="nft-price">{nftParameters.priceInEth} ETH per NFT</p>
    <p id="mint-message">
      {!!address && <span className="wallet-address">Connected with wallet: {address}</span>}
      {isSuccess && (
        <span className="hash">
          <a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
        </span>
      )}
      {(isPrepareError || isError) && (
        <div class="errors">Error: {(prepareError || error)?.message}</div>
      )}
    </p>
  </>
  )
}